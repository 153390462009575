import Navbar from './Navbar';
import FreeSolo from './FreeSoloOf';
import React, { useState, useEffect } from 'react';
import ConnectionFinder from '../api/ConnectionFinder';
import Button from './Button';
import { DataGrid, useGridApiRef, GridToolbarContainer, GridToolbarExport, gridClasses } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { logIn, logOut, setEgon } from "../actions";
import { useNavigate } from 'react-router-dom';
import stateCheck from './Test';
import Cookie from './Cookies';
import './styles/Research.css';
import Loading from './Loading';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ColumnNewView from './ColumnNewView';
import ColumnSetView from './ColumnSetView';
import NavButton from './NavButton';
import Xlsexport from './Xlsexport';
import ManageRows from './ManageRows';
import ColumnsOF from './ColumnsOf';


const Research = () => {
  const [, setCities] = useState([]);
  const [, setAddresses] = useState([]);
  const [, setCivics] = useState([]);
  const [rows, setRows] = useState([]);
  const [cluster, setCluster] = useState("Nessun risultato")
  const [loading, setLoading] = useState(false);
  const [offset, setoffset] = useState(false);
  const apiRef = useGridApiRef();
  const navigate = useNavigate()

  const [clusters, setClusters] = useState({
    AB: true,
    CD: true,
    IT1GB: true,
  })

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    
      return (
        <StyledGridOverlay style={{width: '100%', height: '450px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute'}}>
          <svg
            width="120"
            height="100"
            viewBox="0 0 184 152"
            aria-hidden
            focusable="false"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(24 31.67)">
                <ellipse
                  className="ant-empty-img-5"
                  cx="67.797"
                  cy="106.89"
                  rx="67.797"
                  ry="12.668"
                />
                <path
                  className="ant-empty-img-1"
                  d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                />
                <path
                  className="ant-empty-img-2"
                  d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                />
                <path
                  className="ant-empty-img-3"
                  d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                />
              </g>
              <path
                className="ant-empty-img-3"
                d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
              />
              <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
              </g>
            </g>
          </svg>
          <Box sx={{ mt: 1 }}>Nessun risultato</Box>
        </StyledGridOverlay>
      );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  let headers = {
    "x-access-token": localStorage.getItem('token')
  }
  
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() =>{return ColumnSetView(ColumnsOF,"DgColOf")});
  const dispatch = useDispatch();

  useEffect(() => {
    ConnectionFinder.get("/login1", {
      headers: {
        "x-access-token": localStorage.getItem('token')
      }
    }).then((response) => {
      if(response.data.auth) {
        dispatch(logIn())
      }
      else {
        dispatch(logOut())
      }
    })
},[navigate, dispatch])
  let egon = useSelector(state => state.Egon)
  async function loadResults(){

    let a = document.getElementById('cname').value;
    let b = document.getElementById('address').value;
    let c = document.getElementById('number').value;

    if(!(clusters.AB || clusters.CD || clusters.IT1GB)){
      alert("Devi selezionare almeno un cluster.")
      return;
    }

    if (a.trim() === "")
    {
      alert("Inserisci almeno la città");
      return;
    }
    if (b.trim() === "")
    {
      egon = "";
      dispatch(setEgon(""))
    }
    setLoading(true)
    setoffset(0) 
    ConnectionFinder.post("/require/results", {
      city: a,
      address: b,
      number: c,
      clusters,
      egon,
      offset: 0
    },{headers}).then((response) => {
      setLoading(false)
      if(stateCheck(response, dispatch)) {
        response.data.rows = response.data.rows.map((value)=>{
            const tmp = value[value.length - 1]
            value.splice(4,0,tmp)
            value.pop()
            return value
        })
        setRows(ManageRows(response.data.rows,ColumnsOF,"field"));
        setCluster(response.data.cluster);
        setoffset(response.data.offset)
      }
      setCities([])
      setAddresses([])
      setCivics([])
    })
  }
  const loadmore = ()=>{
    if (rows.length === 0)
    {
      alert("Esegui prima una ricerca normale")
      return
    }
    let a = document.getElementById('cname').value;
    let b = document.getElementById('address').value;
    let c = document.getElementById('number').value;

    if(!(clusters.AB || clusters.CD || clusters.IT1GB)){
      alert("Devi selezionare almeno un cluster.")
      return;
    }

    setLoading(true)
    ConnectionFinder.post("/require/results", {
      city: a,
      address: b,
      number: c,
      clusters,
      egon,
      offset
    },{headers}).then((response) => {
      setLoading(false)
      if(stateCheck(response, dispatch) && !response.data.status) {
        setRows(rows.concat(ManageRows(response.data.rows,ColumnsOF,"field")))
        setCluster(response.data.cluster);
        setoffset(response.data.offset)
      }
      else if (!response.data.status){
        alert("Non ci sono altri risultati da caricare")
      }
      
    })

  }
  const Setview=(newModel) =>{
    setColumnVisibilityModel(newModel);
    ColumnNewView(newModel,ColumnsOF,"DgColOf","ID_SCALA");
  }

  
  return (
    <div style={{background: 'linear-gradient(347deg, rgba(243,17,98,0.927608543417367) 8%, rgba(41,44,59,1) 61%)', paddingBottom: '40px'}}>
      <Navbar />
      <div className='InputContainerOF'>
        <FreeSolo label="Comune" />
        <FreeSolo label="Indirizzo"  />
        <FreeSolo label="Civico" />
        <Button name="Cerca" func={() => loadResults()}/>
      </div>
      <div style={{width: '100%', height: 'fit-content', backgroundColor: 'white', paddingBottom: '10px'}}>
        <span style={{fontSize: '25px', fontWeight: 'bold', marginLeft: '10px', color: 'grey'}}>Filtri</span>
        <br />
        <div style={{marginLeft: '40px'}}>
          <span style={{fontSize: '20px'}}>Cluster:</span>
          <div style={{display: 'flex', flexDirection: 'row', columnGap: '30px', marginLeft: '5px'}}>
            <div >
              <label htmlFor="FTTH" style={{fontSize: "20px",}}>AB</label>
              <input defaultChecked type="checkbox" name="ftth" id="AB" style={{marginLeft: '15px', accentColor: "#ff0f64", transform: "scale(1.5)"}} onClick={() => setClusters({...clusters, AB: !clusters.AB})}/>
            </div>
            <div >
              <label htmlFor="FTTH" style={{fontSize: "20px",}}>CD</label>
              <input defaultChecked  type="checkbox" name="ftth" id="CD" style={{marginLeft: '15px', accentColor: "#ff0f64", transform: "scale(1.5)"}} onClick={() => setClusters({...clusters, CD: !clusters.CD})}/>
            </div>
            <div >
              <label htmlFor="FTTH" style={{fontSize: "20px",}}>IT1GB</label>
              <input defaultChecked type="checkbox" name="ftth" id="IT1GB" style={{marginLeft: '15px', accentColor: "#ff0f64", transform: "scale(1.5)"}} onClick={() => setClusters({...clusters, IT1GB: !clusters.IT1GB})}/>
            </div>
          </div>
        </div>
      </div>
      <NavButton text="Indietro" to="/home"/>
      <p style={{ color: 'white', fontWeight: 'bold', fontSize: '40px',margin: '0', textAlign: 'center'}}>Risultati</p>
      {loading ? <Loading /> : <div className='grid'>
        <DataGrid 
          rows={rows}
          columns={ColumnsOF} 
          apiRef={apiRef} 
          getRowId={(row) => row.ID_SCALA} 
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>{Setview(newModel)}}
          components={{Toolbar: CustomToolbar, NoRowsOverlay: CustomNoRowsOverlay}}/>
      </div>}
      <div className='FieldsContainer'>
        <div className='clusterTag'>
          CLUSTER
          <div className='clusterValue'>{cluster}</div>
        </div>
        <div className='ButtonsContainer'>
          {loading || rows.length === 0 ? <span/> : <Button name="Esporta Come Excel" className='LoadExport' style={{backgroundColor: 'white', color: 'grey'}} func={() =>{Xlsexport(rows,ColumnsOF,30,columnVisibilityModel)}}/>}
          {loading || rows.length === 0 ? <span/> : <Button name="Carica più Risultati" className='LoadExport' style={{backgroundColor: 'white', color: 'grey'}} func={() =>{loadmore()}}/>}
        </div>
      </div>
      <Cookie />
    </div>
  );
}

export default Research;