import React, { useState, useEffect } from "react";
import ConnectionFinder from "../api/ConnectionFinder";
import stateCheck from "./Test";
import { useDispatch } from "react-redux";
import EditItem from "./EditItem";
import './styles/Products.css';
import Alert from "./Alert";
import './styles/Screens.css';


import DeleteIcon from './img/delete.png'

const ProductsManager = () => {

    const headers = {
        "x-access-token": localStorage.getItem('token')
    }

    const [ready, setReady] = useState(false)
    const [preview, setPreview] = useState(
        <img src="" alt="" width={'200px'} height={'auto'} style={{margin: 'auto'}}/>
    )
    const [btnStatus, setBtnStatus] = useState('Crea')
    const [newProduct, setProduct] = useState({})
    const [message, setMessage] = useState('...')
    const [products, setProducts] = useState()
    const [selectedItems1, setSelectedItems] = useState([])
    const [alert, setAlert] = useState()
    let selectedItems = []
    let alertStatus = false, product;

    const dispatch = useDispatch();


    useEffect(() => {
        listUpdate()
        //eslint-disable-next-line
    }, [])

    const seletedItemsHandler = (id) => {
        if(!selectedItems.includes(id)) selectedItems.push(id)
        else selectedItems.splice(selectedItems.indexOf(id), 1)

        setSelectedItems(selectedItems)

    }

    const deleteMultipleProducts = async () => {
        
        await ConnectionFinder.delete('/products/deleteMProducts', {
            products: selectedItems1
        }, {headers})

        listUpdate()
    }

    const listUpdate = () => {
        ConnectionFinder.get('/products/getProductList', {headers}).then((response) => {
            if(stateCheck(response, dispatch)){
                setProducts(           
                    response.data.map((item) => (
                        <tr key={item.id}>
                            <td><input type="checkbox" onClick={() => seletedItemsHandler(item.id)}/></td>
                            <td style={{textAlign: 'center', fontWeight: 'bold'}}>{item.id}</td>
                            <td style={{width: '20%', fontWeight: 'bold'}}>{item.name}</td>
                            <td>{item.description}</td>
                            <td style={{textAlign: 'center'}}>{item.category}</td>
                            <td>{<EditItem id={item.id} update={() => listUpdate()}/>}</td>
                        </tr>
                    ))
                )
            }
        })
    }

    const inputCheck = () => {
        let isFilled = false;
        const ids = ['pName', 'description', 'pImgUrl', 'pCategory', 'pAmzUrl']
        ids.forEach(id => {
            if(document.getElementById(id).value.trim() !== ''){
                isFilled = true
            }else isFilled = false
        })

        if(isFilled){
            if(ready) {
                ids.forEach(id => {
                    document.getElementById(id).value = ''
                    return false;
                })
            }
            return true
            
        }else{
            setMessage('Campi non completi')
            setTimeout(() => setMessage('...'), 2000)
        }

    }

    const productCheck = async () => {
        
        if(!ready) {
            if(!inputCheck()) return
            product = {
                name: document.getElementById('pName').value.trim(),
                description: document.getElementById('description').value.trim(),
                imageUrl: document.getElementById('pImgUrl').value.trim(),
                category: document.getElementById('pCategory').value.trim(),
                amazonUrl: document.getElementById('pAmzUrl').value.trim(),
            }
            setProduct(product)
            document.getElementById('createBtn').style.backgroundColor = 'green'
            setPreview(<img src={product.imageUrl} alt="" width={'200px'} height={'auto'} style={{margin: 'auto'}}/>)
            setBtnStatus('Aggiungi')
            setMessage('Assicurati che i dati siano corretti')
            setReady(true)
        }else{
            await ConnectionFinder.post('/products/createNewProduct', newProduct, {headers}).then((response) => {
                if(stateCheck(response, dispatch)){
                    document.getElementById('createBtn').style.backgroundColor = '#ff0f64'
                    setBtnStatus('Crea')
                    setPreview()
                    setMessage('Elemento aggiunto')
                    setReady(false)
                    inputCheck()
                    listUpdate()
                }
                return;
            })
        }
    }

    const test = () => {
        alertStatus = !alertStatus
        alertStatus ? setAlert(<Alert exit={true} text={`Stai per eliminare ${selectedItems1.length} elementi.`} action={() => test()}/>) : setAlert()
    }
    


    return (
        <div className="screen">
            <div className="cpContainer">
                <h1 style={{textAlign: 'left', marginRight: 'auto', color: '#5F6A6A'}}>Nuovo prodotto</h1>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    {preview}
                    <input type="text" placeholder="Nome prodotto" className="cpInput" id="pName" style={{width: '75%', height: '50px'}}/>  
                    <textarea className="cpInput" style={{maxWidth: '95%', height: '170px', fontFamily: 'arial', resize: 'none'}} id='description' placeholder='Descrizione'></textarea>
                    <input type="text" placeholder="Url dell'immagine" className="cpInput" id="pImgUrl" style={{width: '75%', height: '50px'}}/>
                    <input type="text" placeholder="Categoria prodotto" className="cpInput" id="pCategory" style={{width: '75%', height: '50px'}}/>    
                    <input type="text" placeholder="Url di amazon" className="cpInput" id="pAmzUrl" style={{width: '75%', height: '50px'}}/>                      
                </div>
                <p className="cpMessage">{message}</p>
                <button className="rButton btnShadow" id='createBtn' onClick={() => productCheck()} style={{backgroundColor: '#ff0f64', color: 'white', borderRadius: '12px', width: '200px'}}>{btnStatus}</button>      
            </div>
            <div style={{marginTop: '80px', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',}}>
                <h1 style={{color: '#5F6A6A', marginRight: '65%'}}>Lista dei prodotti</h1>
                <div className="tableFixHead">
                    <table className="productsTable" style={{borderRadius: '13px'}}>
                        <thead className="landingTitle" style={{position: 'sticky', top: 0, borderRadius: '13px', backgroundColor: '#ff0f64'}}>
                            <tr className="pTitleRow" style={{marginBottom: '30px'}}>
                                <th></th>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Descrizione</th>
                                <th>Categoria</th>
                                <th>Modifica</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products}
                        </tbody>
                    </table>
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '80%', margin: 'auto', marginTop: '20px'}}>
                    <p style={{fontSize: '1.3vw', color: '#5F6A6A'}}>Puoi eliminare più elementi selezionandoli nella tabella e premendo qui.</p>
                    <div className="btnShadow" id="deleteBtn" style={{backgroundColor: '#D2042D', width: '80px', height: '50px', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: '30px'}} onClick={() => deleteMultipleProducts()}>
                        <img src={DeleteIcon} alt="" />
                    </div>
                </div>
            </div>
            {alert}
            <button onClick={() => {test()}}>TEST</button>
        </div>
    );
}

export default ProductsManager;