import React from "react";
import Navbar from "./Navbar";
import logoOf from './img/OpenFiberLogoWhite.png';
import logoFc from './img/FiberCopLogoWhite.png';
import logoFibreConnect from './img/FibreConnect.svg';
import { useNavigate } from "react-router-dom";
import "./styles/Home.css";

const Home = () => {

    const navigate = useNavigate();

    return(
        <div style={{height: "92vh"}}>
            <Navbar />
            <div style={{width: '100%', backgroundColor: 'white', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 0}}>
                <h1 className="homeTitle" >Scegli da quale database ricercare</h1>
                <div className="homeContainer">
                    <div onClick={() => navigate("/research", {replace: true})} className="homeCard" style={{ height: "50%", backgroundColor: "#ff0f64", borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                        <img src={logoOf} alt="logoof" style={{width: '100%'}} loading="lazy"/>
                    </div>

                    <div onClick={() => navigate("/researchfc", {replace: true})} className="homeCard" style={{ height: "50%", backgroundColor: "#ff0f64", borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gridArea:'card'}}>
                        <img src={logoFc} alt="logofc" style={{width: '90%'}} loading="lazy"/>
                    </div>

                    <div onClick={() => navigate("/fibreconnect", {replace: true})} className="homeCard" style={{ height: "50%", backgroundColor: "#ff0f64", borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gridArea:'card'}}>
                        <img src={logoFibreConnect} alt="logofc" style={{width: '90%'}} loading="lazy"/>
                    </div>


                </div>
            </div>    
        </div>
    )
}

export default Home;