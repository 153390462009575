import Select from './Select';
const SelectRegions = ({onChange, ClassName}) => {
    const regions = ['Piemonte', "Valle d'Aosta", "Lombardia", "Trentino-Alto Adige", "Veneto", "Friuli-Venezia Giulia", "Liguria", "Emilia-Romagna", "Toscana", "Umbria", "Marche", "Lazio", "Abruzzo", "Molise", "Campania", "Puglia", "Basilicata", "Calabria", "Sicilia", "Sardegna"]

    return (
        <div>
            <Select label='Regione' onChange={onChange} ClassName={ClassName} items={regions} />
        </div>
    )
}
export default SelectRegions;