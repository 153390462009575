import React from "react";

import './styles/Landing.css'

const Landing = () => {

    return(
        <div style={{padding: '10px'}}>            
            <p style={{color: '#ff0f64', fontSize: '5vh', margin: '10px', fontWeight: '600'}}>Benvenuto nel pannello di controllo di FiberFinder</p>
            <p style={{color: 'gray', fontSize: '2.8vh', textAlign: 'left', margin: '10px', fontWeight: '500'}}>Qui potrai trovare tutto ciò che riguarda la gestione del sito FiberFinder, nella sua parte pubblica e privata. Le sezioni sono spiegate di seguito.</p>
            
            <div className="landingGuideSectionList">
                <div className="landingGuideSection">
                    <h1 className="landingTitle" style={{height: '40px', fontSize: '30px'}}>Account</h1>
                    <p className="landingGuideText">Nella sezione ACCOUNT si potranno gestire gli account che avranno accesso all'area privata del sito.</p>
                    <p className="landingGuideText">Si avrà accesso alle informazioni riguardanti gli account già esistenti: username, password (criptata), id e ruolo dell utente.</p>
                    <p className="landingGuideText">(In futuro verrà aggiunta la possibilità di modificare alcune di queste informazioni o eliminare gli account non più necessari.)</p>
                </div>
                <div className="landingGuideSection">
                    <h1 className="landingTitle" style={{height: '40px', fontSize: '30px'}}>Prodotti</h1>
                    <p className="landingGuideText">Nella sezione PRODOTTI saranno disponibili tutti i prodotti presenti nella parte dei consigliati nell'area pubblica del sito.</p>
                    <p className="landingGuideText">Potranno essere aggiunti nuovi prodotti oppure essere modificati o rimossi, ogni prodotto avrà una sua sezione dedicata nell'area apposita del sito in base alla sua categoria.</p>

                </div>
            </div>
        </div>
    )
}

export default Landing;