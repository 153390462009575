import React, {useEffect, useState} from "react";
import {LineChart} from '@mui/x-charts/LineChart'
import ConnectionFinder from "../api/ConnectionFinder";
import Loading from './Loading';
import './styles/Screens.css';

const RequestMonitor = () => {

    const [researchChart, setResearchChart] = useState(<Loading />)
    const [viewChart, setViewChart] = useState(<Loading />)
    const [resList, setResList] = useState()
    const [visList, setVisList] = useState()
    const [totReq, setTotReq] = useState(0)
    const [totVis, setTotVis] = useState(0)

    /**
    * @param {string} date Date to display in the chart.
    */

    const updateChart = (date) => {
        ConnectionFinder.get("/reqNum", {
            headers: {
                "x-access-token": localStorage.getItem('token')
            },
            params: {
                date,
            }

        }).then((res) => {
            let x = [], y = [], c = 0;
            let viewX = [], viewY = [], viewC = 0;
            if(res.data[0].length > 0) {
                res.data[0].forEach((item) => {
                    x.push(item.hour)
                    y.push(item.reqNum)
                    c += item.reqNum
                })
                setTotReq(c)
                setResearchChart(
                    <LineChart 
                    
                        xAxis={[{ data: x, tickMinStep: 1, label: "Ora" }]}
                        series={[
                            {
                                data: y,
                                area: true,
                                color: "#ff0f64",
                                label: "Numero Ricerche",
                                curve: 'linear',
                                showMark: false,
                            },
                        ]}
                    />
                )
            } else {
                setResearchChart(
                    <div style={{height: '400px', display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center'}}>
                        Non ci sono dati disponibili per la giornata indicata
                    </div>
                )
            }
            if(res.data[1].length > 0) {
                res.data[1].forEach((item) => {
                    viewX.push(item.hour)
                    viewY.push(item.visNum)
                    viewC += item.visNum
                })
                setTotVis(viewC) 
                setViewChart(
                    <LineChart 
                    
                        xAxis={[{ data: viewX, tickMinStep: 1, label: "Ora" }]}
                        series={[
                            {
                                data: viewY,
                                area: true,
                                color: "#909497",
                                label: "Numero Visite",
                                curve: 'linear',
                                showMark: false,
                            },
                        ]}
                    />
                )
            }
            else {
                setViewChart(
                    <div style={{height: '400px', display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center'}}>
                        Non ci sono dati disponibili per la giornata indicata
                    </div>
                )
            }


        })
    }
    
    const displayDayList = () => {
        ConnectionFinder.get('/reqPerDay', {
            headers: {
                "x-access-token": localStorage.getItem('token')
            }
        }).then((res) => {
            const researchList = res.data[0].map((row) => (
                <tr key={row.date}>
                    <td onClick={() => updateChart(row.date)} style={{color: '#ff0f64', textDecoration: 'underline', cursor: 'pointer'}}>{row.date}</td>
                    <td>{row.reqNum}</td>
                </tr>
            )) 
            const visitList = res.data[1].map((row) => (
                <tr key={row.date}>
                    <td onClick={() => updateChart(row.date)} style={{color: '#ff0f64', textDecoration: 'underline', cursor: 'pointer'}}>{row.date}</td>
                    <td>{row.visNum}</td>
                </tr>
            )) 

            setResList(researchList)
            setVisList(visitList)
        })
    }


    useEffect(() => {
        updateChart('today')
        displayDayList();
        // eslint-disable-next-line
    },[])

    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
            <h1 style={{fontSize: '2.4rem', fontWeight: 'bold', backgroundColor: '#ff0f64', color: 'white', width: '100%', textAlign: 'center', height: '60px', marginTop: '-30px'}} >Numero ricerche</h1>
            <p style={{fontSize: '1.4rem'}}>In questo grafico viene mostrato il numero di ricerche effettuate oggi suddivise in ogni ora.</p>
            <div className="chartContainer">
                {researchChart}
            </div>
            <h3>Totale ricerche</h3>{totReq}
            <table className="reqMonitTable" style={{width: '60%', textAlign: 'center', marginBottom: '30px', marginTop: '10px'}}>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Num Ricerche</th>
                    </tr>
                </thead>
                <tbody style={{fontWeight: "bold", border: '1px solid gray', color: 'gray'}}>
                    {resList}
                </tbody>
            </table>

            {/* Numero di visite */}

            <br />
            <h1 style={{fontSize: '2.4rem', fontWeight: 'bold', backgroundColor: '#ff0f64', color: 'white', width: '100%', textAlign: 'center', height: '60px'}} >Numero visite</h1>
            <p style={{fontSize: '1.4rem'}}>In questo grafico viene mostrato il numero di visite effettuate oggi suddivise in ogni ora.</p>
            <div className="chartContainer">
                {viewChart}
            </div>
            <h3>Totale visite</h3>{totVis}
            <table className="reqMonitTable" style={{width: '60%', textAlign: 'center', marginBottom: '30px', marginTop: '10px'}}>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Num visite</th>
                    </tr>
                </thead>
                <tbody style={{fontWeight: "bold", border: '1px solid gray', color: 'gray'}}>
                    {visList}
                </tbody>
            </table>

        </div>
    )
}

export default RequestMonitor;