const xlsx = require("xlsx")
const Xlsexport = async (rows,col,wd,model) => {
    const alltrue = Object.keys(model) === 0
    if (rows.length > 200000){ 
        alert("Hai superato il limite massimo per l'export")
        return
    }
    let output = [[]];
    output[0] = col.map((value)=>{
        if (model[value.field] || alltrue)
            return value.field 
        return undefined
    }).filter((element)=>{return element !== undefined})
    if (alltrue)
        output = output.concat(rows.map(()=>Object.values()))
    else{
        output = output.concat(rows.map((value)=>{
            for (const prop in value){
                if (!model[prop])
                    delete value[prop]
            }
            return Object.values(value)
        }))
    }
    var workbook = xlsx.utils.book_new();
    var worksheet = xlsx.utils.aoa_to_sheet(output,{})
    worksheet['!cols'] =  Array(col.length).fill({wch: wd})
    xlsx.utils.book_append_sheet(workbook,worksheet,"Pagina1")
    return xlsx.writeFile(workbook,"Export.xlsx")
}

export default Xlsexport;