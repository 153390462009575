import React from "react";

import './styles/Alert.css';

const Alert = (props) => {

    const text = props.text;
    const action = props.action;
    const exit = props.exit;
    const critical = props.critical;

    return(
        <div className="alertBackground">
            <div className="cpContainer alert" style={{padding: 0, alignItems: 'center', textAlign: 'center'}}>
                <h2 className='landingTitle' style={{borderRadius: 0, width: '100%', marginBottom: 'auto', borderTopLeftRadius: '13px', borderTopRightRadius: '13px' }}>{props.title}</h2>
                <p className="alertText">{text}</p>
                <div style={{margin: 'auto', width: '90%', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center'}}>
                    {exit && <button className="rButton btnShadow alertBtn" onClick={() => exit()} style={{ color: 'white', backgroundColor: '#ff0f64', borderRadius: '13px'}}>Annulla</button>}
                    <button className="rButton btnShadow alertBtn" onClick={() => action()} style={{marginBottom: 'auto', color: 'white', backgroundColor: critical ? '#D2042D' : '#ff0f64', borderRadius: '13px', marginLeft: '10px'}}>{props.actionText}</button>
                </div>
            </div>
        </div>
    )
}   

Alert.defaultProps = {
    title: 'Attenzione',
    actionText: 'Prosegui',
}

export default Alert;