import React from "react";
import { Navigate, Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux'
    
const AdminRoutes = () => {
    const auth = useSelector(state => state.loginStatus)
    const role = useSelector(state => state.roleManager)
    return (auth && role === 'admin') ? <Outlet /> : <Navigate to={"/research"}/>;
}




export default AdminRoutes;