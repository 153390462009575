import Cookies from "universal-cookie";

const ColumnSetView = (columns,cookieName) =>{
    let stato ={};
    let cookie = new Cookies();
    let colstr = cookie.get(cookieName);
    if (colstr === undefined)
    {
        columns.forEach(element => {
            stato[element.field] = true;
        });
        return (stato);
    }
    for (let i = 0; i < columns.length; i++)
    {
        let propname = columns[i].field;
        if (colstr[i] === "0")
            stato[propname] = false; 
        else
            stato[propname] = true;
    }
    return (stato);
}
export default ColumnSetView;