import React from "react";
import LoadingGif from './img/loading.gif'
import './styles/Research.css';
const Loading = () => {
    return(
        <div className="grid" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
            <img src={LoadingGif} style={{width: '64px', height: '64px'}} alt="loading"/>
        </div>
    )
}

export default Loading;