const ColumnsOF = [
    { field: 'ID_SCALA', headerName: 'ID_SCALA', width: 150, sortable:false },
    { field: 'REGIONE', headerName: 'REGIONE', width: 150 , sortable:false},
    { field: 'PROVINCIA', headerName: 'PROVINCIA', width: 150 , sortable:false},
    { field: 'COMUNE', headerName: 'CODICE_COMUNE', width: 150 , sortable:false},
    { field: 'Denominazione_in_italiano', headerName: 'COMUNE', width: 150 , sortable:false},
    { field: 'FRAZIONE', headerName: 'FRAZIONE', width: 150 , sortable:false},
    { field: 'PARTICELLA_TOP', headerName: 'PARTICELLA_TOP', width: 150 , sortable:false},
    { field: 'INDIRIZZO', headerName: 'INDIRIZZO', width: 300 , sortable:false},
    { field: 'CIVICO', headerName: 'CIVICO', width: 150 , sortable:false},
    { field: 'SCALA_PALAZZINA', headerName: 'SCALA_PALAZZINA', width: 150 , sortable:false},
    { field: 'CODICE_VIA', headerName: 'CODICE_VIA', width: 150 , sortable:false},
    { field: 'ID_BUILDING', headerName: 'ID_BUILDING', width: 320 , sortable:false},
    { field: 'COORDINATE_BUILDING', headerName: 'COORDINATE_BUILDING', width: 250 , sortable:false},
    { field: 'POP', headerName: 'POP', width: 150 , sortable:false},
    { field: 'TOTALE_UI', headerName: 'TOTALE_UI', width: 150 , sortable:false},
    { field: 'STATO_UI', headerName: 'STATO_UI', width: 150 , sortable:false},
    { field: 'STATO_SCALA_PALAZZINA', headerName: 'STATO_SCALA_PALAZZINA', width: 150 , sortable:false},
    { field: 'DATA_RFC_INDICATIVA', headerName: 'DATA_RFC_INDICATIVA', width: 150 , sortable:false},
    { field: 'DATA_RFC_EFFETTIVA', headerName: 'DATA_RFC_EFFETTIVA', width: 150 , sortable:false},
    { field: 'DATA_RFA_INDICATIVA', headerName: 'DATA_RFA_INDICATIVA', width: 150 , sortable:false},
    { field: 'DATA_RFA_EFFETTIVA', headerName: 'DATA_RFA_EFFETTIVA', width: 150 , sortable:false},
    { field: 'DATA_ULTIMA_MODIFICA_RECORD', headerName: 'DATA_ULTIMA_MODIFICA_RECORD', width: 150 , sortable:false},
    { field: 'DATA_ULTIMA_VARIAZIONE_STATO_BUILDING', headerName: 'DATA_ULTIMA_VARIAZIONE_STATO_BUILDING', width: 150 , sortable:false},
    { field: 'DATA_ULTIMA_VARIAZIONE_STATO_SCALA_PALAZZINA', headerName: 'DATA_ULTIMA_VARIAZIONE_STATO_SCALA_PALAZZINA', width: 150 , sortable:false},
    { field: 'ID_EGON_CIVICO', headerName: 'ID_EGON_CIVICO', width: 250 , sortable:false},
    { field: 'ID_EGON_STRADA', headerName: 'ID_EGON_STRADA', width: 250 , sortable:false},
  ];
export default ColumnsOF;