import { logOut, setRole } from "../actions";

const stateCheck = (res, dispatch) => {
  if(res.data.err) {
    alert("Sessione terminata. Rieffettua il login.")
    dispatch(logOut())
    dispatch(setRole(null))
    return false;
  }
  return true;
}

export default stateCheck;