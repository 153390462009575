import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {


  const items = props.items;

  return (
    <Box sx={{ width: '20vw',
      '& .MuiInputBase-root': {
        borderRadius: '15px',
        borderWidth: '3px',
        margin: '0',
        marginLeft:'5px',
        
      },
      '@media screen and (max-width: 600px)':{
          width: 'calc(70% + 6px)',
          marginRight: '6px',
          marginTop: '10px',
          '& .MuiInputBase-root': {
            height: '50px',          
          },
          '& .MuiInputLabel-root': {
            marginTop: '-2px',
          },

      },
      
    }}
      
    >
      
      <FormControl fullWidth>

        <InputLabel id="demo-simple-select-label" sx={{
          '&.MuiFormLabel-root': {
            marginLeft: '25px',

            '&.Mui-focused': {
              color: '#ff0f64',
              backgroundColor: 'white',
              marginLeft: 0
            },
            "&.MuiInputLabel-shrink": {
              marginLeft: '5px',
              marginTop: '0',
            }
          }
        }}>{props.label}</InputLabel>
        <Select
            defaultValue={''}
            labelId="region-label"
            id="region"
            className={props.className}
            onChange={props.onChange}
            label={props.label}
            sx={{
                '& .MuiOutlinedInput-notchedOutline ': {
                  borderWidth: '3px',
                  borderColor: '#ff0f64',
                  
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'gray'
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey",
                  borderWidth: "3px"
                },

            }}
        >
          {items.map((item) => (
            <MenuItem value={item} key={Math.random()}>{item}</MenuItem>            
          ))}
          
        </Select>
      </FormControl>
    </Box>
  );
}

