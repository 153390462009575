import { useState } from "react";
import ConnectionFinder from "../api/ConnectionFinder";
import Vero from './img/Vero.png'
import Falso from './img/Falso.png'
import Download from './img/Download.png'
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const ContractsLog = () =>{
    const [rows, setRows] = useState()
    const FetchParams = () =>{
        const Nome = document.getElementById('Nome').value;
        const Cognome = document.getElementById('Cognome').value;
        const headers = {
            "x-access-token": localStorage.getItem('token')
        }
        ConnectionFinder.get(`/require/contractsLog`, {
            headers,
            params: {
                Nome,
                Cognome
            }
        }).then((response) => {
            const arr = []
            if (response.data.length === 0)
                alert('Nessun contratto trovato')
                //console.log(response.data)
            for (const index in response.data){
                if (response.data[index].Signed === 0)
                    response.data[index].Signed = <img src={Falso} alt="Vero" style={{width: '50px',height: '50px'}}/>
                else
                    response.data[index].Signed = <img src={Vero} alt="Vero" style={{width: '50px',height: '50px'}}/>
                    const arr1 = []
                //console.log(response.data[index])
                for (const el in response.data[index]){
                    arr1.push(<div className={el + " column"}><span className="colel" id={el + '.' + index} >{response.data[index][el]}</span></div>)
                    //console.log(response.data[index][el])
                }
                arr1.push(<div className="Log column"><span className="colel"><button className="bntdownload" style={{width:'50px', height:'50px'}} onClick={()=>GetLog(document.getElementById('Id.' + index).outerText)}><img src={Download} alt="Download" style={{width: '40px',height: '40px'}}/></button></span></div>)
                //console.log(document.getElementById('Id.' + index).outerText)
                arr.push(
                    <div className="Contractsrow accountI">
                        {arr1}
                    </div>
                )
            }
            setRows(arr)
        })
    }

    const GetLog = async(ID) =>{
        console.log(ID)
        const headers = {
            "x-access-token": localStorage.getItem('token')
        }
        console.log(headers)
        await ConnectionFinder.get(`/require/GetLog`, {
            headers,
            responseType: 'arraybuffer',
            params: {
                ID
            }
        }).then((res) => {
            const zip = new JSZip();
            return zip.loadAsync(res.data)
        }).then((zip)=>{
            zip.generateAsync({type:"blob"}).then((content)=>{
                saveAs(content, "Bollettini.zip");
            })
        }).catch((err)=>{
            console.log(err)
        })
    }

    return(
        <div className="FlexContainer">
            <div className="FlexContainer">
                <div className="FlexContainer" style={{flexDirection: 'row', width: '65%'}}>
                    <input style={{minHeight: '7vh'}} type="text" placeholder="Nome" className="cpInput" id="Nome"/>  
                    <input style={{minHeight: '7vh'}} type="text" placeholder="Cognome" className="cpInput" id="Cognome"/>   
                </div>
                <button className="rButton btnShadow" onClick={FetchParams} style={{backgroundColor: '#ff0f64', color: 'white', borderRadius: '12px'}}>Cerca</button>      
            </div>
            <div className="FlexContainer">
                <h1 style={{color: '#5F6A6A', marginRight: 'auto', marginLeft: '10%'}}>Log dei contratti</h1>
                <div className='accountListContainer' style={{width: '80%'}}> 
                    <div className='listHeader' >
                        <div className="column"><span className="ColumnsTitle">ID</span></div>
                        <div className="column"><span className="ColumnsTitle">Nome</span></div>
                        <div className="column"><span className="ColumnsTitle">Cognome</span></div>
                        <div className="column"><span className="ColumnsTitle">Data</span></div>
                        <div className="column"><span className="ColumnsTitle">Firmato</span></div>
                        <div className="column"><span className="ColumnsTitle">Log</span></div>
                    </div>
                    <a id="downloadAnchorElem" href={() => false} style={{display:"tr"}}> </a>
                    <div className='accountListScroll'>
                        {rows}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContractsLog;