//convert array of array to an array of objects with properties (columns)
const ManageRows = (rawData,columns,fieldname) =>{
  rawData = rawData.map((value)=>{
      let fixedValue = {};
      for (let i = 0; i < value.length; i++){
        fixedValue[columns[i][fieldname]] = value[i]
      }
      return fixedValue
  })
  return rawData
}
export default ManageRows;