const ColumnsFc = [
    { field: 'PROGRESSIVO_RIGA', headerName: 'PROGRESSIVO_RIGA', width: 200, sortable:false},
    { field:'DR', headerName:'DR', width: 200, sortable:false},
    { field:'CODICE_EGON_CLIENTE', headerName:'CODICE_EGON_CLIENTE', width: 200, sortable:false },
    { field:'CODICE_EGON_STRADA_CLIENTE', headerName:'CODICE_EGON_STRADA_CLIENTE', width: 200, sortable:false },
    { field:'CATEGORIA', headerName:'CATEGORIA', width: 200, sortable:false },
    { field:'COPERTURA_PER_PROSSIMITA', headerName:'COPERTURA_PER_PROSSIMITA', width: 200, sortable:false },
    { field:'CODICE_TOP_CLIENTE', headerName:'CODICE_TOP_CLIENTE', width: 200, sortable:false },
    { field:'PROVINCIA_CLIENTE', headerName:'PROVINCIA_CLIENTE', width: 200, sortable:false },
    { field:'COMUNE_CLIENTE', headerName:'COMUNE_CLIENTE', width: 200, sortable:false },
    { field:'CODICE_ISTAT_COMUNE', headerName:'CODICE_ISTAT_COMUNE', width: 200, sortable:false },
    { field:'PART_TOP_CLIENTE', headerName:'PART_TOP_CLIENTE', width: 200, sortable:false },
    { field:'STRADA_CLIENTE', headerName:'STRADA_CLIENTE', width: 200, sortable:false },
    { field:'CIVICO_CLIENTE', headerName:'CIVICO_CLIENTE', width: 200, sortable:false },
    { field:'CODICE_TOP_SITE', headerName:'CODICE_TOP_SITE', width: 200, sortable:false },
    { field:'FLAG_OPA', headerName:'FLAG_OPA', width: 200, sortable:false },
    { field:'COPERTURA_SLU', headerName:'COPERTURA_SLU', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_SLU', headerName:'VELOCITA_DOWN_SLU', width: 200, sortable:false },
    { field:'VELOCITA_UP_SLU', headerName:'VELOCITA_UP_SLU', width: 200, sortable:false },
    { field:'DISTANZA_SLU', headerName:'DISTANZA_SLU', width: 200, sortable:false },
    { field:'EDR_SLU', headerName:'EDR_SLU', width: 200, sortable:false },
    { field:'TIPO_EDR_SLU', headerName:'TIPO_EDR_SLU', width: 200, sortable:false },
    { field:'ARMADIO_PADRE_SLU', headerName:'ARMADIO_PADRE_SLU', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_NGA_VULA_FTTCab', headerName:'COPERTURA_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_NGA_VULA_FTTCab', headerName:'PIANIFICAZIONE_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_BITSTREAM_NGA_VULA_FTTCab', headerName:'VELOCITA_DOWN_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'VELOCITA_UP_BITSTREAM_NGA_VULA_FTTCab', headerName:'VELOCITA_UP_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'COPERTURA_EVDSL_BITSTREAM_NGA_VULA_FTTCab', headerName:'COPERTURA_EVDSL_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_EVDSL_BITSTREAM_NGA_VULA_FTTCab', headerName:'VELOCITA_DOWN_EVDSL_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'VELOCITA_UP_EVDSL_BITSTREAM_NGA_VULA_FTTCab', headerName:'VELOCITA_UP_EVDSL_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'DISTANZA_BITSTREAM_NGA_VULA_FTTCab', headerName:'DISTANZA_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'EDR_BITSTREAM_NGA_VULA_FTTCab', headerName:'EDR_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'TIPO_EDR_BITSTREAM_NGA_VULA_FTTCab', headerName:'TIPO_EDR_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'CLLI_CENTRALE_OLT_BITSTREAM_NGA_VULA_FTTCab', headerName:'CLLI_CENTRALE_OLT_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'PREVISIONE_AMPL_BITSTREAM_NGA_VULA_FTTCab', headerName:'PREVISIONE_AMPL_BITSTREAM_NGA_VULA_FTTCab', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_NGA_VULA_FTTH', headerName:'COPERTURA_BITSTREAM_NGA_VULA_FTTH', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_NGA_VULA_FTTH', headerName:'PIANIFICAZIONE_BITSTREAM_NGA_VULA_FTTH', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_NGA_VULA_XGSPON', headerName:'COPERTURA_BITSTREAM_NGA_VULA_XGSPON', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_NGA_VULA_XGSPON', headerName:'PIANIFICAZIONE_BITSTREAM_NGA_VULA_XGSPON', width: 200, sortable:false },
    { field:'EDR_BITSTREAM_NGA_VULA_FTTH', headerName:'EDR_BITSTREAM_NGA_VULA_FTTH', width: 200, sortable:false },
    { field:'TIPO_EDR_BITSTREAM_NGA_VULA_FTTH', headerName:'TIPO_EDR_BITSTREAM_NGA_VULA_FTTH', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_ASIM_ATM', headerName:'COPERTURA_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_ASIM_ATM', headerName:'PIANIFICAZIONE_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_6dB_BITSTREAM_ASIM_ATM', headerName:'VELOCITA_DOWN_6dB_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'VELOCITA_UP_6dB_BITSTREAM_ASIM_ATM', headerName:'VELOCITA_UP_6dB_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_11dB_BITSTREAM_ASIM_ATM', headerName:'VELOCITA_DOWN_11dB_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'VELOCITA_UP_11dB_BITSTREAM_ASIM_ATM', headerName:'VELOCITA_UP_11dB_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_12dB_BITSTREAM_ASIM_ATM', headerName:'VELOCITA_DOWN_12dB_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'VELOCITA_UP_12dB_BITSTREAM_ASIM_ATM', headerName:'VELOCITA_UP_12dB_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'DISTANZA_BITSTREAM_ASIM_ATM', headerName:'DISTANZA_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'EDR_BITSTREAM_ASIM_ATM', headerName:'EDR_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'TIPO_EDR_BITSTREAM_ASIM_ATM', headerName:'TIPO_EDR_BITSTREAM_ASIM_ATM', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_ASIM_ETHERNET', headerName:'COPERTURA_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_ASIM_ETH', headerName:'PIANIFICAZIONE_BITSTREAM_ASIM_ETH', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_6dB_BITSTREAM_ASIM_ETHERNET', headerName:'VELOCITA_DOWN_6dB_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'VELOCITA_UP_6dB_BITSTREAM_ASIM_ETHERNET', headerName:'VELOCITA_UP_6dB_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_11dB_BITSTREAM_ASIM_ETHERNET', headerName:'VELOCITA_DOWN_11dB_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'VELOCITA_UP_11dB_BITSTREAM_ASIM_ETHERNET', headerName:'VELOCITA_UP_11dB_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_12dB_BITSTREAM_ASIM_ETHERNET', headerName:'VELOCITA_DOWN_12dB_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'VELOCITA_UP_12dB_BITSTREAM_ASIM_ETHERNET', headerName:'VELOCITA_UP_12dB_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'DISTANZA_BITSTREAM_ASIM_ETHERNET', headerName:'DISTANZA_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'EDR_BITSTREAM_ASIM_ETHERNET', headerName:'EDR_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'TIPO_EDR_BITSTREAM_ASIM_ETHERNET', headerName:'TIPO_EDR_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'TIPO_DSLAM_BITSTREAM_ASIM_ETHERNET', headerName:'TIPO_DSLAM_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'PREVISIONE_AMPL_BITSTREAM_ASIM_ETHERNET', headerName:'PREVISIONE_AMPL_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'STATO_SERVIZIO_BITSTREAM_ASIM_ETHERNET', headerName:'STATO_SERVIZIO_BITSTREAM_ASIM_ETHERNET', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', headerName:'COPERTURA_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', headerName:'PIANIFICAZIONE_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', width: 200, sortable:false },
    { field:'DISTANZA_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', headerName:'DISTANZA_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', width: 200, sortable:false },
    { field:'EDR_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', headerName:'EDR_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', width: 200, sortable:false },
    { field:'TIPO_EDR_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', headerName:'TIPO_EDR_BITSTREAM_SIMM_ATM_2M_4M_6M_8M', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', headerName:'COPERTURA_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', headerName:'PIANIFICAZIONE_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', width: 200, sortable:false },
    { field:'DISTANZA_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', headerName:'DISTANZA_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', width: 200, sortable:false },
    { field:'EDR_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', headerName:'EDR_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', width: 200, sortable:false },
    { field:'TIPO_EDR_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', headerName:'TIPO_EDR_BITSTREAM_SIMM_ATM_1M_1.6M_4M_Bonding', width: 200, sortable:false },
    { field:'COPERTURA_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', headerName:'COPERTURA_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', headerName:'PIANIFICAZIONE_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', width: 200, sortable:false },
    { field:'DISTANZA_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', headerName:'DISTANZA_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', width: 200, sortable:false },
    { field:'EDR_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', headerName:'EDR_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', width: 200, sortable:false },
    { field:'TIPO_EDR_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', headerName:'TIPO_EDR_BITSTREAM_SIMM_ETHERNET_2M_4M_6M_8M_10M', width: 200, sortable:false },
    { field:'COPERTURA_ULL', headerName:'COPERTURA_ULL', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_ULL_ADSL2+', headerName:'VELOCITA_DOWN_ULL_ADSL2+', width: 200, sortable:false },
    { field:'VELOCITA_UP_ULL_ADSL2+', headerName:'VELOCITA_UP_ULL_ADSL2+', width: 200, sortable:false },
    { field:'VELOCITA_DOWN_ULL_VDSL', headerName:'VELOCITA_DOWN_ULL_VDSL', width: 200, sortable:false },
    { field:'VELOCITA_UP_ULL_VDSL', headerName:'VELOCITA_UP_ULL_VDSL', width: 200, sortable:false },
    { field:'DISTANZA_ULL', headerName:'DISTANZA_ULL', width: 200, sortable:false },
    { field:'EDR_ULL', headerName:'EDR_ULL', width: 200, sortable:false },
    { field:'TIPO_EDR_ULL', headerName:'TIPO_EDR_ULL', width: 200, sortable:false },
    { field:'COPERTURA_WLR', headerName:'COPERTURA_WLR', width: 200, sortable:false },
    { field:'DISTANZA_WLR', headerName:'DISTANZA_WLR', width: 200, sortable:false },
    { field:'EDR_WLR', headerName:'EDR_WLR', width: 200, sortable:false },
    { field:'TIPO_EDR_WLR', headerName:'TIPO_EDR_WLR', width: 200, sortable:false },
    { field:'COPERTURA_CIRCUITI_TERM_ETH_FIBRA_OTTICA', headerName:'COPERTURA_CIRCUITI_TERM_ETH_FIBRA_OTTICA', width: 200, sortable:false },
    { field:'PIANIFICAZIONE_CIRCUITI_TERM_ETH_FIBRA_OTTICA', headerName:'PIANIFICAZIONE_CIRCUITI_TERM_ETH_FIBRA_OTTICA', width: 200, sortable:false },
    { field:'FASCIA_CIRCUITI_TERM_ETH_FIBRA_OTTICA', headerName:'FASCIA_CIRCUITI_TERM_ETH_FIBRA_OTTICA', width: 200, sortable:false },
    { field:'EDR_CIRCUITI_TERM_ETH_FIBRA_OTTICA', headerName:'EDR_CIRCUITI_TERM_ETH_FIBRA_OTTICA', width: 200, sortable:false },
    { field:'TIPO_EDR_CIRCUITI_TERM_ETH_FIBRA_OTTICA', headerName:'TIPO_EDR_CIRCUITI_TERM_ETH_FIBRA_OTTICA', width: 200, sortable:false },
    { field:'COPERTURA_FWA-W', headerName:'COPERTURA_FWA-W', width: 200, sortable:false },
  ];
  export default ColumnsFc