import React, { useEffect } from "react";
const loadGoogleMapsScript = (callback) => {
  if (typeof window.google === "object" && typeof window.google.maps === "object") {
    // Google Maps script is already loaded
    callback();
  } else {
    // Check if the script is already in the document
    const existingScript = document.querySelector(`script[src*="https://maps.googleapis.com/maps/api/js?key="]`);
    
    if (!existingScript) {
      // Create a new script tag if it's not already in the document
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = callback;
      document.head.appendChild(script);
    } else {
      // If the script is already in the document, wait for it to load
      existingScript.addEventListener("load", callback);
    }
  }
};

const GoogleMap = ({ coordinates, markerCoords, address }) => {
  useEffect(() => {
    loadGoogleMapsScript(() => {
      const google = window.google;
      console.log(coordinates,"coordinates",markerCoords, "Marker");
      const bounds = new google.maps.LatLngBounds();
      if (coordinates.length === 0) return
      // Extend bounds to include all coordinates
      coordinates.forEach((coord) => {
        bounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
      });

      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 10,
        center: bounds.getCenter(),
      });
      map.fitBounds(bounds);
      if (coordinates.length > 3){
          // Define the area using coordinates
        const areaPolygon = new google.maps.Polygon({
          paths: coordinates,
          strokeColor: "#FF0000", // Border color
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000", // Fill color
          fillOpacity: 0.35,
        });
        areaPolygon.setMap(map);
      }
      

      // Display the polygon on the map

      markerCoords.forEach((marker,index) => {
        const markerObj = new google.maps.Marker({
          position: { lat: marker[0], lng: marker[1] },
          map: map,
        });

        const infoWindow = new google.maps.InfoWindow({
          content: `<div style="color: black;">${marker[2]}</div>`,
        });

        markerObj.addListener("click", () => {
          infoWindow.open(map, markerObj);
        });
      })
    });
  }, [coordinates,markerCoords,address]);

  return <div id="map" style={{ width: "90%", height: "90%" }} />;
};

export default GoogleMap;
