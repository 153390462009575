//login status actions
export const logIn = () => {
    return {
        type: 'LOGIN'
    }
}

export const logOut = () => {
    return {
        type: 'LOGOUT'
    }
}

//role actions

export const setRole = (role) => {
    return {
        type: 'SETROLE',
        role: role
    }
}

export const setEgon = (egon) => {
    return {
        type: 'EGON',
        value: egon
    }
}