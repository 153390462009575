import { Autocomplete,TextField } from "@mui/material"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
const AutocompleteCustom = ({ suggestions,value, onTextChange,onSelect, label,id, className,setvalue }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
              "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            // Default transform is "translate(14px, 20px) scale(1)""
            // This lines up the label with the initial cursor position in the input
            // after changing its padding-left.
            transform: "translate(34px, 20px) scale(0.9);",
          },
          "&.Mui-focused .MuiInputLabel-outlined": {
            color: "#ff0f64"
          },
          "& .MuiInputLabel-outlined": {
            fontSize: "18px"
      
          },  
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderRadius: "15px",
            borderWidth: "3px",
            fontSize: "17px",
          },
          "@media screen and (max-width: 700px )":
          {
              "& .MuiInputBase-root": {
                width: '100%',
                marginLeft: "0px",
                height: '50px',
              },
              '& .MuiInputLabel-root': {
                marginTop: '-6px',
              },
          },
        },
        inputRoot: {
          color: "black",
          backgroundColor: "white",
          borderRadius: "15px",
          "@media screen and (max-width: 700px )":
          {
            "& .MuiInputBase-input":{
              marginTop: '-4px'
            },
          },
          
          // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
          '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            // Default left padding is 6px
            borderRadius: "15px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ff0f64",
            borderRadius: "15px",
            borderWidth: "3px"
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderRadius: "15px",
            borderWidth: "3px"
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "ff0f64",
            borderRadius: "15px",
            borderWidth: "3px",
            color: "red"
          },
          "&.Mui-checked .MuiOutlinedInput-notchedOutline": {
            borderColor: "ff0f64",
            borderRadius: "15px",
            borderWidth: "3px",
            color: "red"
          }
        }
      }));
    const classes = useStyles();
    return (
        <Autocomplete
            options={suggestions}
            noOptionsText="Nessun risultato"
            id={id}
            classes={classes}
            className= {className}
            
            onBlur={onSelect}
            renderInput={(params) => (
                <TextField  {...params} value={value}   label={label} variant="outlined" onChange={(e)=>{typeof onTextChange === "function" ? onTextChange(e) : setvalue(e.target.value);setvalue(e.target.value)}} />
            )}
        />
    )
}
export default AutocompleteCustom