import React from "react";
import './styles/style.css'


const Button = (props) => {
    
    return(
        <div onClick={props.func} style={props.style} id={props.id}className="btn">
            {props.name}
        </div>
    )

}

export default Button;