import Cookies from "universal-cookie";
const ColumnNewView = (model,columns,cookieName,Colock) =>{    
    let cookie = new Cookies();
    let colstr = "";
    columns.forEach(element => {
      if(model[element.field] || element.field === Colock || Object.keys(model).length === 0)
        colstr += '1';
      else  
        colstr += '0';
    });
    cookie.set(cookieName,colstr,{ path: '/', sameSite:"none"},);
    return(true);
}

export default ColumnNewView;