import loginStatusReducer from './loginStatus'
import roleReducer from './roleReducer'
import Res from './res'
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    loginStatus: loginStatusReducer,
    roleManager: roleReducer,
    Egon: Res,
})

export default rootReducer;