import React from "react";
import { Navigate, Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux'
    
const ProtectedRoutes = () => {
    const auth = useSelector(state => state.loginStatus)
    return auth ? <Outlet /> : <Navigate to={"/"}/>;
}




export default ProtectedRoutes;