import React from 'react';
import { Routes, Route } from "react-router-dom";
import Research from './Components/Research';
import Login from './Components/Login';
import NotFound from './Components/NotFound';
import ProtectedRoutes from './Components/ProtectedRoutes';
import AdminRoutes from './Components/AdminRoutes';
import './App.css';
import CitysearchFc from './Components/SearchFc';
import Home from './Components/Home';
import FibreConnect from './Components/FibreConnect';
import ControlPanel from './Components/ControlPanel';

function App() {
  return (
    <div>
      <Routes>
          <Route exact path='/' element={<Login/>}/>
          <Route element={<ProtectedRoutes/>}>
            <Route exact path='/home' element={<Home />}/>
            <Route exact path='/research' element={<Research />}/>
            <Route exact path='/researchfc' element={<CitysearchFc/>}/>
            <Route exact path='/fibreconnect' element={<FibreConnect />}/>
          </Route>
          <Route element={<AdminRoutes/>}>
            <Route exact path='/cp' element={<ControlPanel />}/>
          </Route>

          <Route path='*' element={<NotFound />} />
      </Routes>
    <div className='bg'></div>
  </div>
);
}

export default App;
