import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import './styles/Screens.css';
import ContractsLog from './ContractsLog';
import ConnectionFinder from '../api/ConnectionFinder';
import { useDispatch } from 'react-redux';
import { logIn, logOut } from "../actions";

import AccountIconP from './img/aWhite.png';
import ProductsIcon from './img/products.png';
import GraphIcon from './img/graph.png';
import HomeIcon from './img/home.png';
import fUploadIcon from './img/fupload.png';
import Log from './img/Log.png';


import Landing from './Landing';
import AccountManager from "./AccountManager";
import ProductsManager from "./ProductsManager";
import RequestMonitor from './RequestMonitor';
import ImportFile from './ImportFile';

const drawerWidth = 180;


export default function ControlPanel(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const [screen, setScreen] = useState({title: 'Pannello di controllo', component: <Landing />});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

    useEffect(() => {
      ConnectionFinder.get("/login1", {
          headers: {
              "x-access-token": localStorage.getItem('token')
          }
      }).then((response) => {
          if(response.data.auth) {
              dispatch(logIn())
          }
          else {
              dispatch(logOut())
          }
      })
  },[navigate, dispatch])
  const CpListItem = ({title, component, imgSrc}) =>{
      return(
        <>
          <ListItem  disablePadding onClick={() => setScreen({title: title, component: component})}>
            <ListItemButton>
              <ListItemIcon>
                    <img src={imgSrc} className='Icon' alt="" ></img>
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        </>
      )
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
          <ListItem  disablePadding onClick={() => navigate("/home")}>
            <ListItemButton>
              <ListItemIcon>
                    <img src={HomeIcon} alt="" ></img>
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItemButton>
          </ListItem>
          <CpListItem title={"Account"} component = {<AccountManager />} imgSrc={AccountIconP}/>
          <CpListItem title={"Prodotti"} component = {<ProductsManager />} imgSrc={ProductsIcon}/>
          <CpListItem title={"Analytics"} component = {<RequestMonitor />} imgSrc={GraphIcon}/>
          <CpListItem title={"Ricerca Excel"} component={ <ImportFile />} imgSrc={fUploadIcon}/>
          <CpListItem title={"Log Contratti"} component={<ContractsLog/>} imgSrc={Log}/>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: '#ff0f64',
          display: 'flex', 
          alignItems: 'center',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }}}
          >
            <MenuIcon fontSize='large'/>
          </IconButton>
          <Typography noWrap component="div" className='screenTitle' variant='p'>
            {screen.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#ff0f64', color: 'white'},
            '& .MuiTypography-root': {fontSize: '1.2rem'}
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#ff0f64', color: 'white' },
            '& .MuiTypography-root': {fontSize: '1.2rem'}
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, paddingTop: '30px'} 
      }
      >
        <Toolbar />
        {screen.component}
      </Box>
    </Box>
  );
}
