import React, { useState } from "react";
import ConnectionFinder from "../api/ConnectionFinder";
import { useDispatch } from "react-redux";
import stateCheck from "./Test";

import EditIcon from './img/edit.png';
import CloseIcon from './img/sclose.png';

const EditItem = (props) => {

    const headers = {
        "x-access-token": localStorage.getItem('token')
    }

    const productId = props.id
    const listUpdate = props.update

    const [confirm, setConfirm] = useState(false)

    const [isActive, setActive] = useState(false)

    const [preview, setPreview] = useState(
        <img src="" alt="" width={'200px'} height={'auto'} style={{margin: 'auto'}}/>
    )

    const dispatch = useDispatch();
    const [message, setMessage] = useState(<p className="cpMessage">...</p>)

    const getProductData = () => {
        ConnectionFinder.get('/products/getProduct', {
            headers, 
            params: {
                id: productId,
            }
        }).then((response) => {
            if(stateCheck(response, dispatch)){
                const product = response.data[0];

                setPreview(<img src={product.imageUrl} alt="" width={'200px'} height={'auto'} style={{margin: 'auto', marginTop: '20px'}}/>)
                document.getElementById('peName').value = product.name
                document.getElementById('peDescription').value = product.description
                document.getElementById('peImgUrl').value = product.imageUrl
                document.getElementById('peCategory').value = product.category
                document.getElementById('peAmzUrl').value = product.amazonUrl
            }

        })
    }

    const editProductData = async () => {
        await ConnectionFinder.put('/products/editProduct', {
            id: productId,
            name: document.getElementById('peName').value.trim(),
            description: document.getElementById('peDescription').value.trim(),
            imageUrl: document.getElementById('peImgUrl').value.trim(),
            category: document.getElementById('peCategory').value.trim(),
            amazonUrl: document.getElementById('peAmzUrl').value.trim()

        }, {headers}).then((response) => {
            if(stateCheck(response, dispatch)){
                setActive(!isActive)
                listUpdate()
            }
        })

    }

    const deleteProduct = async () => {

        if(!confirm){
            setMessage( <>
                <p className="cpMessage">Vuoi davvero eliminarlo?</p>
                <p style={{color: '#ff0f64', borderRadius: '12px', width: '200px', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => cancelDeleteOperation()}>Annulla</p>
            </>)
            setConfirm(true)
        }else {
            await ConnectionFinder.delete('/products/deleteProduct', {
                headers, 
                params: {
                    id: productId,
                }
            }).then((response) => {
                if(stateCheck(response, dispatch)){
                    setActive(!isActive)
                    listUpdate()
                    setConfirm(false)
                }
            })
        }

    }

    const cancelDeleteOperation = () => {
        setConfirm(false)
        setMessage(<p className="cpMessage">...</p>)
    }

    return(
        <div>
            <div className="btnShadow editBtn" onClick={() => {
                setActive(!isActive)
                getProductData()
                }}>
                <img width={'80%'} height='80%' src={EditIcon} alt="" style={{marginTop: '3px'}}/>
            </div>
        

            {isActive && <div className="editScreenContex">
                <div className="cpContainer" style={{maxHeight: '87%', marginBottom: '3%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%'}}>
                        <h1 style={{textAlign: 'left', marginRight: 'auto', color: '#5F6A6A'}}>Modifica prodotto</h1>
                        <img src={CloseIcon} alt="" onClick={() => setActive(!isActive)} style={{width: '45px', height: '45px'}} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '40%', overflowY: 'auto'}}>
                        {preview}
                        <input type="text" placeholder="Nome prodotto" className="cpInput" id="peName" style={{width: '75%', height: '50px'}}/>  
                        <textarea className="cpInput" style={{maxWidth: '90%', minHeight: '170px', fontFamily: 'arial', resize: 'none'}} id='peDescription' placeholder='Descrizione'></textarea>
                        <input type="text" placeholder="Url dell'immagine" className="cpInput" id="peImgUrl" style={{width: '75%', height: '50px'}}/>
                        <input type="text" placeholder="Categoria prodotto" className="cpInput" id="peCategory" style={{width: '75%', height: '50px'}}/>    
                        <input type="text" placeholder="Url di amazon" className="cpInput" id="peAmzUrl" style={{width: '75%', height: '50px'}}/>                      
                    </div>
                    {message}
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '80%'}}>
                        <button className="rButton btnShadow" id='createBtn' style={{backgroundColor: '#ff0f64', color: 'white', borderRadius: '12px', width: '200px'}} onClick={() => editProductData()}>Applica</button>
                        <button className="rButton btnShadow" id='createBtn' style={{backgroundColor: '#D2042D', color: 'white', borderRadius: '12px', width: '200px'}} onClick={() => deleteProduct()} >Elimina</button>                        
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default EditItem;